import { selector } from '@src/libs/recoil/recoil';
import { getGstInfoApi } from '@src/utils/api/api';
import { ApiResFormat } from '@src/utils/api/api-types';
import { GET_GST_INFO } from './home-actions';
import type { GstInfo } from './home-types';

export const gstInfoSelector = selector({
  key: GET_GST_INFO,
  get: async () => {
    const gstResponse: ApiResFormat<GstInfo[]> = await getGstInfoApi();
    const gstOptions = gstResponse.data.data.map((gstData) => ({
      ...gstData,
      label: gstData.company.legal_name,
      value: gstData.company.cin
        ? gstData.company.cin
        : gstData.company.gstin[0],
    }));

    return {
      ...gstResponse.data,
      gstOptions,
    };
  },
});
