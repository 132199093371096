import React, { useState, lazy } from 'react';
import classNames from 'classnames/bind';
import { useRecoilValue } from '@src/libs/recoil/recoil';
import { useNavigate } from '@src/libs/router/react-router';
import SuspenseLoader from '@src/uikit/SuspenseLoader/suspense-loader';
import { getLoadingPageUrl } from '@src/libs/router/route-helper';
import { Select, Option } from '@src/uikit/select/select';
import { SvgChevronRight, SvgSearch } from '@turbocomplynpm/icons';
import { Flex } from '@turbocomplynpm/flex';
import { useCinHook } from '@src/report/use-cin-hook';
import * as styles from './home-search.module.css';
import { gstInfoSelector } from './atoms/home-atoms';

const SvgBackgroundGlow = lazy(() => import('@src/icons/svg-bg-glow'));

const cx = classNames.bind(styles);

export function HomeSearch() {
  const gstInfo = useRecoilValue(gstInfoSelector);
  const navigate = useNavigate();
  const [gstin, setGstin] = useState('');
  const [options, setOptions] = useState(gstInfo.gstOptions);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { handleSetCinAtom } = useCinHook();
  const handleGstinChange = (value: string | string[]) => {
    if (typeof value === 'string') {
      const selectedCompany = gstInfo.gstOptions.find(
        (option) => option.value === value,
      );
      const companyName = selectedCompany?.label ?? '';
      setGstin(value);
      handleSetCinAtom({
        cin: value,
        gst: selectedCompany?.company.gstin ?? [],
      });
      const pageUrl = getLoadingPageUrl({
        cin: value,
        companyName,
        gstins: selectedCompany?.company.gstin ?? [],
      });
      navigate(pageUrl);
    }
  };

  const handleOnSearch = (input: string) => {
    if (input) {
      setIsSearchOpen(true);
    } else {
      setIsSearchOpen(false);
    }
    const filterOptions = gstInfo.gstOptions.filter(({ label }) =>
      label.toLowerCase().includes(input.toLowerCase()),
    );
    setOptions(filterOptions);
  };

  return (
    <div className={cx('container')}>
      <SuspenseLoader loaderType="skeleton">
        <SvgBackgroundGlow className={cx('background-glow')} />
      </SuspenseLoader>
      <Select
        placeholder="Search for a company"
        className={cx('search-select')}
        value={gstin}
        onChange={handleGstinChange}
        showSearch
        onSearch={handleOnSearch}
        filterOption={false}
        open={isSearchOpen}
        suffixIcon={<SvgSearch />}
      >
        {options.map((option) => (
          <Option key={option.value} title={option.label} value={option.value}>
            <Flex between row middle className={cx('option')}>
              {option.label}{' '}
              <SvgChevronRight size="medium" color="currentColor" />
            </Flex>
          </Option>
        ))}
      </Select>
    </div>
  );
}
