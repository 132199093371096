import React, { lazy } from 'react';
import classNames from 'classnames/bind';
import { Flex } from '@turbocomplynpm/flex';
import SuspenseLoader from '@src/uikit/SuspenseLoader/suspense-loader';
import * as styles from './home.module.css';
import { HomeSearch } from './home-search';

const IntroText = lazy(() => import('./intro-text'));

const cx = classNames.bind(styles);

export function Home() {
  return (
    <Flex middle center className={cx('full-height')}>
      <SuspenseLoader loaderType="skeleton">
        <IntroText />
      </SuspenseLoader>
      <SuspenseLoader loaderType="skeleton">
        <HomeSearch />
      </SuspenseLoader>
    </Flex>
  );
}
