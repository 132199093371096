import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { SkeletonLoader } from '@src/uikit/SkeletonLoader/skeleton-loader';
import Layout from './layout';
import { Home } from './home/home';
import ErrorPage from './error-page';
import SuspenseLoader from './uikit/SuspenseLoader/suspense-loader';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'loading/:cin',
        loader: () => <SkeletonLoader />,
        async lazy() {
          const { HomePageLoader } = await import(
            '@src/loading/home-page-loader'
          );
          return {
            element: (
              <SuspenseLoader loaderType="skeleton">
                <HomePageLoader />
              </SuspenseLoader>
            ),
          };
        },
      },
      {
        path: 'report/:cin',
        loader: () => <SkeletonLoader />,
        async lazy() {
          const { Report } = await import('@src/report/report');
          return {
            element: (
              <SuspenseLoader loaderType="skeleton">
                <Report />
              </SuspenseLoader>
            ),
          };
        },
      },
      {
        path: 'summary/:cin',
        loader: () => <SkeletonLoader />,
        async lazy() {
          const { Summary } = await import('@src/summary/summary');
          return {
            element: (
              <SuspenseLoader loaderType="skeleton">
                <Summary />
              </SuspenseLoader>
            ),
          };
        },
        children: [
          {
            path: 'gst',
            index: true,
            loader: () => <SkeletonLoader />,
            async lazy() {
              const { GstSummary } = await import(
                '@src/summary/gst-summary/gst-summary-page'
              );
              return {
                element: (
                  <SuspenseLoader loaderType="skeleton">
                    <GstSummary />
                  </SuspenseLoader>
                ),
              };
            },
          },
          {
            path: 'roc',
            loader: () => <SkeletonLoader />,
            async lazy() {
              const { WipLoader } = await import(
                '@src/uikit/wip-loader/wip-loader'
              );
              return {
                element: (
                  <SuspenseLoader>
                    <WipLoader title="Work in progress" />
                  </SuspenseLoader>
                ),
              };
            },
          },
          {
            path: 'traces',
            loader: () => <SkeletonLoader />,
            async lazy() {
              const { WipLoader } = await import(
                '@src/uikit/wip-loader/wip-loader'
              );
              return {
                element: (
                  <SuspenseLoader>
                    <WipLoader title="Work in progress" />
                  </SuspenseLoader>
                ),
              };
            },
          },
          {
            path: 'pf',
            loader: () => <SkeletonLoader />,
            async lazy() {
              const { WipLoader } = await import(
                '@src/uikit/wip-loader/wip-loader'
              );
              return {
                element: (
                  <SuspenseLoader>
                    <WipLoader title="Work in progress" />
                  </SuspenseLoader>
                ),
              };
            },
          },
        ],
      },
    ],
  },
]);
